import type { PDFDocumentProxy } from 'pdfjs-dist';
import { useRef, useContext, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { useResizeObserver } from 'usehooks-ts';
import Translation from '../../translation/Translation';
import LangContext from '../app/App';

// this import is required. Even if it seems to work without it, it breaks in a production build
import 'pdfjs-dist/build/pdf.worker.entry';
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();

export function PDFViewer({ file }: { file: string }) {
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const langContext = useContext(LangContext);
  const translate = (key: string): string => Translation.getTranslation(key, langContext);
  const ref = useRef<HTMLDivElement>(null);
  const { width = 0 } = useResizeObserver({ ref });

  const onDocumentLoadSuccess = ({ numPages: nextNumPages }: PDFDocumentProxy) => {
    setNumPages(nextNumPages);
    setPageNumber(1);
  }

  const onDocumentLoadError = (error: Error) => {
    setNumPages(0);
    setPageNumber(0);
  }

  const changePage = (offset: number) => setPageNumber(prevPageNumber => prevPageNumber + offset);

  const previousPage = () => changePage(-1);

  const nextPage = () => changePage(1);

  return (
    <>
      <div>
        {numPages > 1 && <>
          <p>{translate('page')}: {pageNumber} / {numPages}</p>
          <button
            type="button"
            disabled={pageNumber <= 1}
            onClick={previousPage}
          >
            {translate('pdfPrevPage')}
          </button>
          <button
            type="button"
            disabled={pageNumber >= numPages}
            onClick={nextPage}
          >
            {translate('pdfNextPage')}
          </button>
        </>
        }
      </div>
      <div ref={ref} className="container">
        <Document
          file={file}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={onDocumentLoadError}
        >
          <Page
            width={width}
            pageNumber={pageNumber} />
        </Document>
      </div>
    </>
  );
}
